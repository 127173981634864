import {
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  inject,
  Input,
  OnInit,
  Output,
  Renderer2,
  signal,
} from '@angular/core';
import {
  delay,
  distinctUntilChanged,
  map,
  startWith,
  takeWhile,
} from 'rxjs/operators';
import {
  coerceBooleanProperty,
  coerceNumberProperty,
} from '@angular/cdk/coercion';
import { Subject } from 'rxjs';
import { state, style, trigger } from '@angular/animations';
import { AnimateService } from './animate.service';
// Animations
import {
  beat,
  bounce,
  flip,
  headShake,
  heartBeat,
  jello,
  pulse,
  rubberBand,
  shake,
  swing,
  tada,
  wobble,
} from './attention-seekers';
import {
  bounceIn,
  bumpIn,
  fadeIn,
  flipIn,
  jackInTheBox,
  landing,
  rollIn,
  slideIn,
  zoomIn,
} from './entrances';
import { bounceOut, fadeOut, hinge, rollOut, zoomOut } from './exits';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export type appAnimateSpeed = 'slower' | 'slow' | 'normal' | 'fast' | 'faster';
export type appAnimations =
  // Attention seekers
  | 'beat'
  | 'bounce'
  | 'flip'
  | 'headShake'
  | 'heartBeat'
  | 'jello'
  | 'pulse'
  | 'rubberBand'
  | 'shake'
  | 'swing'
  | 'tada'
  | 'wobble'
  // Entrances
  | 'bumpIn'
  | 'bounceIn'
  | 'bounceInDown'
  | 'bounceInLeft'
  | 'bounceInUp'
  | 'bounceInRight'
  | 'fadeIn'
  | 'fadeInRight'
  | 'fadeInLeft'
  | 'fadeInUp'
  | 'fadeInDown'
  | 'slideInRight'
  | 'slideInLeft'
  | 'slideInUp'
  | 'slideInDown'
  | 'flipInX'
  | 'flipInY'
  | 'jackInTheBox'
  | 'landing'
  | 'rollIn'
  | 'zoomIn'
  | 'zoomInDown'
  | 'zoomInLeft'
  | 'zoomInUp'
  | 'zoomInRight'
  // Exits
  | 'bounceOut'
  | 'bounceOutDown'
  | 'bounceOutUp'
  | 'bounceOutRight'
  | 'bounceOutLeft'
  | 'fadeOut'
  | 'fadeOutRight'
  | 'fadeOutLeft'
  | 'fadeOutDown'
  | 'fadeOutUp'
  | 'hinge'
  | 'rollOut'
  | 'zoomOut'
  | 'zoomOutDown'
  | 'zoomOutRight'
  | 'zoomOutUp'
  | 'zoomOutLeft'
  // None
  | 'none';

@Component({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  selector: '[appAnimate]',
  template: '<ng-content></ng-content>',
  animations: [
    trigger('animate', [
      // Attention seekers
      ...beat,
      ...bounce,
      ...flip,
      ...headShake,
      ...heartBeat,
      ...jello,
      ...pulse,
      ...rubberBand,
      ...shake,
      ...swing,
      ...tada,
      ...wobble,
      // Entrances
      ...bumpIn,
      ...bounceIn,
      ...fadeIn,
      ...slideIn,
      ...flipIn,
      ...jackInTheBox,
      ...landing,
      ...rollIn,
      ...zoomIn,
      // Exits
      ...bounceOut,
      ...fadeOut,
      ...hinge,
      ...rollOut,
      ...zoomOut,
      // None
      state('none', style('*')),
      state('idle-none', style('*')),
    ]),
  ],
})
export class AnimateComponent implements OnInit {
  public animating = signal(false);
  public animated = signal(false);

  @HostBinding('@animate')
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  public trigger: {
    value: appAnimations | 'none' | 'idle-none';
    params?: { timing?: string; delay?: string };
  };
  /** Selects the animation to be played */
  @Input('appAnimate')
  public animate!: appAnimations;
  /** Emits at the end of the animation */
  @Output()
  public readonly begin = new EventEmitter<void>();
  /** Emits at the end of the animation */
  @Output()
  public readonly done = new EventEmitter<void>();
  /** When defined, triggers the animation on element scrolling in the viewport by the specified amount. Values from 0 to 1 */
  @Input()
  public aos = 0;
  @Input()
  public once = true;
  @HostBinding('@.disabled')
  @Input()
  public disabled = false;
  /** When true, keeps the animation idle until the next replay triggers */
  @Input()
  public paused = false;
  private readonly _changeDetectorRef: ChangeDetectorRef =
    inject(ChangeDetectorRef);
  // Animating parameters
  private _timing: string | undefined;
  private readonly _replay$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private readonly _elementRef: ElementRef,
    private readonly _animateService: AnimateService,
    private readonly _renderer2: Renderer2,
    private readonly _destroyRef: DestroyRef,
  ) {}

  private _delay: string | undefined;

  /** Delays the animation */
  @Input()
  public set delay(delay: string) {
    // Coerces the input into a number first
    const value = coerceNumberProperty(delay, 0);
    if (value) {
      // Turns a valid number into a ms _delay
      this._delay = `${value}ms`;
    } else {
      // Test the string for a valid _delay combination
      this._delay = /^\d+(?:ms|s)$/.test(delay) ? delay : '';
    }
  }

  /** Speeds up or slows down the animation */
  @Input()
  public set speed(speed: appAnimateSpeed) {
    // Turns the requested speed into a valid _timing
    this._timing =
      {
        slower: '3s',
        slow: '2s',
        normal: '1s',
        fast: '500ms',
        faster: '300ms',
      }[speed || 'normal'] || '1s';
  }

  /** Replays the animation */
  @Input()
  public set replay(replay: number) {
    // Re-triggers the animation again on request (skipping the very fist value)
    if (!!this.trigger && coerceBooleanProperty(replay)) {
      this.trigger = this._idle;
      this._replay$.next(true);
    }
    // Re-triggers the animation on request
    if (replay > 0) {
      let played = 0;
      const int = setInterval(() => {
        this._replay$.next(false);
        this._replay$.next(true);
        played += 1;
      }, 5000);
      if (played >= replay) {
        clearInterval(int);
      }
    }
  }

  private get _idle() {
    return { value: `idle-${this.animate}` as appAnimations };
  }

  private get _play() {
    const params = {};
    // Builds the params object, so, leaving to the default values when undefined
    if (this._timing) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      params['timing'] = this._timing;
    }
    if (this._delay) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      params['delay'] = this._delay;
    }

    return { value: this.animate, params };
  }

  @HostListener('@animate.start')
  public animationStart() {
    this.animating.set(true);
    this.animated.set(false);
    this.begin.emit();
  }

  @HostListener('@animate.done')
  public animationDone() {
    this.animating.set(false);
    this.animated.set(true);
    this.done.emit();

    /**
     * Removes spurious 'animation' style from the element once done with the animation.
     * This behaviour has been observed when running on iOS devices where for some reason
     * the animation engine do not properly clean-up the animation style using cubic-bezier()
     * as its timing function. The issue do not appear with ease-in/out and others.
     * */
    this._renderer2.removeStyle(this._elementRef.nativeElement, 'animation');
  }

  public ngOnInit(): void {
    // Triggers the animation based on the input flags
    this._replay$
      .pipe(
        takeUntilDestroyed(this._destroyRef),
        // Waits the next round to re-trigger
        delay(0),

        // Triggers immediately when not _paused
        startWith(!this.paused),

        // Builds the AOS observable from the common service
        this._animateService.trigger(this._elementRef, this.aos),

        // Stop taking the first on trigger when once is set
        takeWhile((trigger) => !trigger || !this.once, true),

        // Maps the trigger into animation states
        map((trigger) => (trigger ? this._play : this._idle)),

        // Always start with idle
        startWith(this._idle),

        // Eliminates multiple triggers
        distinctUntilChanged(),
      )
      .subscribe((trigger) => {
        this.trigger = trigger;
        this._changeDetectorRef.markForCheck();
      });
  }
}
