import { animate, state, style, transition } from '@angular/animations';

export const slideIn = [
  state('idle-slideInRight', style({ translate: '0 0', opacity: '0' })),
  state('idle-slideInLeft', style({ translate: '0 0', opacity: '0' })),
  state('idle-slideInUp', style({ translate: '0 0', opacity: '0' })),
  state('idle-slideInDown', style({ translate: '0 0', opacity: '0' })),

  transition(
    '* => slideInRight',
    [
      style({ translate: '-500px 0', opacity: '0' }),
      animate('{{timing}} {{delay}} ease-in', style('*')),
    ],
    {
      params: { timing: '1s', delay: '' },
    },
  ),

  transition(
    '* => slideInLeft',
    [
      style({ translate: '500px 0', opacity: '0' }),
      animate('{{timing}} {{delay}} ease-in', style('*')),
    ],
    { params: { timing: '1s', delay: '' } },
  ),

  transition(
    '* => slideInUp',
    [
      style({ translate: '0 500px', opacity: '0' }),
      animate('{{timing}} {{delay}} ease-in', style('*')),
    ],
    {
      params: { timing: '1s', delay: '' },
    },
  ),

  transition(
    '* => slideInDown',
    [
      style({ translate: '0 -500px', opacity: '0' }),
      animate('{{timing}} {{delay}} ease-in', style('*')),
    ],
    { params: { timing: '1s', delay: '' } },
  ),
];
